<template>
  <div class="chanyeDt">
   <span style="color:#ffffff">产业地图</span>
  </div>
</template>
<script>
export default {
  name: "Charts",
  data() {
    return {
    
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.chanyeDt {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
}
</style>